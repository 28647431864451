import { css } from 'styled-components';

export default css`
  padding-bottom: 16px;
  user-select: none;

  &:not(:is(:last-of-type)) {
    border-bottom: solid 1px rgba(112, 174, 221, 0.28);
  }

  & > {
    summary {
      align-items: center;
      color: #3763e5;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      gap: 8px;
      left: 0;
      letter-spacing: 0.84px;
      line-height: 16px;
      list-style: none;
      padding-top: 31px;
      padding-bottom: 0;
      position: sticky;
      text-transform: uppercase;
      top: 0;
      z-index: 1;

      &:not(:is([open] > summary)) {
        &::after {
          transform: rotate(-180deg);
          transform-origin: center;
        }
      }

      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        background-color: #3763e5;
        content: '';
        display: block;
        height: 16px;
        mask: center
          url(${process.env.PUBLIC_URL}/assets/vectors/arrows/caret.svg)
          no-repeat;
        mask-size: cover;
        width: 16px;
      }
    }

    ul {
      --percentage: (
          100 - (var(--scroll-ratio) * (100 * (var(--scroll-ratio) + 1)))
        ) * 1%;

      margin-top: 12px;
      mask-composite: add;
      mask-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) calc(var(--percentage)),
        black
      );
      mask-mode: alpha;

      &:not(:hover) {
      }
    }
  }
`;
