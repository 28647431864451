import { Suspense } from 'react';

import { Placeholder } from 'components/widgets';

import type * as Types from './types';
import use from './hooks';
import Chat from './chat';
import Header from './header';
import Sidebar from './sidebar';

export default (({ className }) => {
  const { ref } = use();

  return (
    <div className={className} ref={ref}>
      <Suspense fallback={<Placeholder />}>
        <Header />
        <main>
          <Chat />
          <Sidebar />
        </main>
        <footer></footer>
      </Suspense>
    </div>
  );
}) as Types.Default;
