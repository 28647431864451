import { Route, Routes } from 'react-router-dom';

import type * as Types from './types';
import Intro from './intro';
import Question from './question';

export default (({ className }) => (
  <section className={className}>
    <Routes>
      <Route path="/" Component={Intro} />
    </Routes>
    <Question />
  </section>
)) as Types.Default;
