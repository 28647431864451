import type * as Types from './types';
import use from './hooks';

export default (({ children, className, name, value, ...props }) => {
  const { defaultChecked, id } = use(props);

  return (
    <div className={className}>
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}) as Types.Default;
