import { css } from 'styled-components';

import { scroll } from 'style/behaviors';

import Settings from './settings';

export default css`
  border-top: solid 1px rgba(112, 174, 221, 0.28);
  flex-grow: 1;
  margin-top: 24px;
  position: relative;

  & > {
    h3 {
      display: none;
    }

    article {
      ${scroll};

      height: calc(100% - 7px);
      left: 0;
      overflow-y: auto;
      padding: 0 7px 0 20px;
      position: absolute;
      top: 7px;
      width: calc(100% - 34px);
    }
  }

  ${Settings} {
    z-index: 2;
  }
`;
