import type * as Types from './types';
import use from './hooks';

export default (({ className }) => {
  const { href } = use();

  return (
    <header className={className}>
      <h3>
        <a href={href} title="Solsten">
          Solsten
        </a>
        <span> proudly presents:</span>
      </h3>
      <p>
        <span>You're logged as </span>
        <a data-entity="profile" href={href} title="Fernando Camargo">
          Fernando Camargo
        </a>
        <span>, a </span>
        <a data-entity="company" href={href} title="Nintendo">
          Nintendo
        </a>
        <span> user.</span>
      </p>
      <nav>
        <h4>Actions:</h4>
        <ul>
          <li>
            <a href={href} title="See your 3 unread notifications">
              <span>See your </span>
              <strong>3</strong>
              <em> unread notifications</em>
              <span>.</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}) as Types.Default;
