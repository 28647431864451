import { css } from 'styled-components';

export default css`
  display: flex;
  position: relative;

  input {
    clip-path: polygon(0 0);
    height: 0;
    position: absolute;
    width: 0;
  }

  label {
    cursor: pointer;
    display: block;
  }
`;
