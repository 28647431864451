import { cloneElement } from 'react';

import type * as Types from './types';
import use from './hooks';

export default ((props) => {
  const { ref } = use(props);

  return cloneElement(props.children, { ref });
}) as Types.Default;
