import { css } from 'styled-components';

export default css`
  --chat-width: 836px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  padding-bottom: 40px;

  & > {
    article,
    form {
      margin: 0 auto;
      width: var(--chat-width);
    }

    article {
      flex-grow: 1;
    }
  }
`;
