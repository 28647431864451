import { css } from 'styled-components';

import * as Shapes from './shapes';

export default css`
  & > {
    a {
      --shape: ${Shapes.Container};

      background-color: rgba(110, 161, 255, 0.16);
      color: #40699d;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      display: block;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.1s ease, color 0.1s ease;
      white-space: nowrap;

      &:is(li > a):not(:empty) {
        clip-path: var(--shape-path);

        &:focus,
        &:hover {
          background-color: #3763e5;
          color: #fff;
        }
      }
    }
  }
`;
