import { Radio } from 'components/widgets/fields';

import type * as Types from './types';

export default (({ className }) => (
  <form className={className}>
    <fieldset>
      <legend>Settings</legend>
      <fieldset data-field="display">
        <legend>Display</legend>
        <Radio name="display" value="chronologically" defaultChecked>
          Chronologically
        </Radio>
        <Radio name="display" value="hierarchically">
          Hierarchically
        </Radio>
      </fieldset>
      <div>
        <button type="submit">Save</button>
      </div>
    </fieldset>
  </form>
)) as Types.Default;
