import type * as Types from './types';
import use from './hooks';

import { Shape } from 'components/widgets';

export default (({ children, className, title }) => {
  const { href } = use();

  return (
    <li className={className}>
      <Shape>
        <a href={href} title={title}>
          {children}
        </a>
      </Shape>
    </li>
  );
}) as Types.Default;
