import { Shape } from 'components/widgets';

import type * as Types from './types';
import use from './hooks';

export default (({ className }) => {
  const { onSubmit, ref } = use();

  return (
    <form className={className} onSubmit={onSubmit} ref={ref}>
      <fieldset>
        <legend>Start a new chat</legend>
        <div data-field="question">
          <label htmlFor="question">Your question:</label>
          <Shape>
            <textarea
              autoComplete="off"
              rows={1}
              defaultValue=""
              id="question"
              name="question"
              placeholder="Ask me anything about your product or customers"
            />
          </Shape>
        </div>
        <div aria-roledescription="controls">
          <Shape slots={2}>
            <button type="submit">
              <strong>Ask Solsten</strong>
            </button>
          </Shape>
        </div>
      </fieldset>
    </form>
  );
}) as Types.Default;
