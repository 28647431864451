export const Container = `
  M 0, <%= calc(height * 0.5) %> C 0, <%= calc(height * 0.296) %>,
  <%= calc(width * 0.003) %>, <%= calc(height * 0.146) %>,
  <%= calc(width * 0.005) %>,
  <%= calc(height * 0.066) %> C <%= calc(width * 0.006) %>,
  <%= calc(height * 0.027) %>, <%= calc(width * 0.011) %>, 0,
  <%= calc(width * 0.018) %>,
  0 H <%= calc(width * 0.982) %> C <%= calc(width * 0.989) %>, 0,
  <%= calc(width * 0.994) %>, <%= calc(height * 0.027) %>,
  <%= calc(width * 0.995) %>,
  <%= calc(height * 0.066) %> C <%= calc(width * 0.997) %>,
  <%= calc(height * 0.146) %>, <%= width %>, <%= calc(height * 0.296) %>,
  <%= width %>, <%= calc(height * 0.5) %> C <%= width %>,
  <%= calc(height * 0.704) %>, <%= calc(width * 0.997) %>,
  <%= calc(height * 0.854) %>, <%= calc(width * 0.995) %>,
  <%= calc(height * 0.934) %> C <%= calc(width * 0.994) %>,
  <%= calc(height * 0.973) %>, <%= calc(width * 0.989) %>, <%= height %>,
  <%= calc(width * 0.982) %>,
  <%= height %> H <%= calc(width * 0.018) %> C <%= calc(width * 0.011) %>,
  <%= height %>, <%= calc(width * 0.006) %>, <%= calc(height * 0.973) %>,
  <%= calc(width * 0.005) %>,
  <%= calc(height * 0.934) %> C <%= calc(width * 0.003) %>,
  <%= calc(height * 0.854) %>, 0, <%= calc(height * 0.704) %>, 0,
  <%= calc(height * 0.5) %> Z
`;
