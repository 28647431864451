import { css } from 'styled-components';

export default css`
  scrollbar-color: rgba(110, 161, 255, 0.16) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(110, 161, 255, 0.16);
  }
`;
