import { Shape } from 'components/widgets';
import { Radio } from 'components/widgets/fields';

import type * as Types from './types';
import use from './hooks';
import Results from './results';

export default (({ className }) => {
  const { href } = use();

  return (
    <Shape>
      <aside className={className}>
        <h2>Chat history</h2>
        <nav>
          <h4>Actions:</h4>
          <ul>
            <li>
              <Shape>
                <a href={href} title="New chat">
                  New chat
                </a>
              </Shape>
            </li>
          </ul>
        </nav>
        <form>
          <fieldset>
            <legend>Search through your chat history</legend>
            <Shape>
              <fieldset data-field="level">
                <Shape slots={2}>
                  <legend>Level</legend>
                </Shape>
                <Radio name="level" value="me" defaultChecked>
                  Me
                </Radio>
                <Radio name="level" value="team">
                  Team
                </Radio>
                <Radio name="level" value="company">
                  Company
                </Radio>
              </fieldset>
            </Shape>
            <div data-field="keywords">
              <label htmlFor="keywords">Keywords:</label>
              <Shape>
                <input
                  autoComplete="off"
                  id="keywords"
                  name="keywords"
                  placeholder="Search chats"
                  type="text"
                />
              </Shape>
            </div>
            <div>
              <button type="submit">Submit</button>
            </div>
          </fieldset>
        </form>
        <Results />
      </aside>
    </Shape>
  );
}) as Types.Default;
