import { css } from 'styled-components';

import { Radio } from 'components/widgets/fields';

export default css`
  position: absolute;
  right: 21px;
  top: 32px;

  legend,
  button[type='submit'] {
    display: none;
  }

  [data-field='display'] {
    display: flex;
    gap: 4px;

    ${Radio} {
      label {
        background-color: transparent;
        border-radius: 4px;
        font-size: 0;
        height: 28px;
        position: relative;
        transition: background-color 0.5s ease;
        width: 28px;

        &:hover,
        &:focus {
          &::after {
            background-color: #3763e5;
          }
        }

        &:is(input:checked + label) {
          background-color: rgba(110, 161, 255, 0.1);

          &::after {
            background-color: #3763e5;
          }
        }

        &:is(input[value='chronologically'] + label) {
          &::after {
            mask-image: url(${process.env
              .PUBLIC_URL}/assets/vectors/icons/clock.svg);
          }
        }

        &:is(input[value='hierarchically'] + label) {
          &::after {
            mask-image: url(${process.env
              .PUBLIC_URL}/assets/vectors/icons/file-tree.svg);
          }
        }

        &::after {
          background-color: #84a6d1;
          content: '';
          height: 16px;
          left: 50%;
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: cover;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: background-color 0.25s ease;
          width: 16px;
        }
      }
    }
  }
`;
