import property from 'lodash-es/property';
import { css } from 'styled-components';

import * as Shapes from './shapes';

export default css`
  position: relative;

  legend,
  label {
    display: none;
  }

  [data-field='question'] {
    display: flex;
  }

  textarea {
    --shape: ${Shapes.Field};

    background-color: #fff;
    border: none;
    clip-path: var(--shape-path);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    outline: none;
    padding: 16px 139px 16px 16px;
    resize: none;
    width: calc(100% - 155px);

    &::placeholder {
      color: #40699d;
    }
  }

  [aria-roledescription='controls'] {
    bottom: 8px;
    position: absolute;
    right: 8px;
  }

  button[type='submit'] {
    --shape-0: ${Shapes.Button.Container};
    --shape-1: ${Shapes.Button.Border};

    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: block;
    filter: drop-shadow(2px 6px 8px rgba(55, 99, 229, 0.35))
      drop-shadow(1px 3px 3px rgba(55, 99, 229, 0.35))
      drop-shadow(-2px -4px 5px rgba(255, 255, 255, 0.35));
    font-family: ${property('theme.typography.secondary')};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    strong {
      background-image: radial-gradient(
          circle at -50% -50%,
          rgba(255, 255, 255, 0.38) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(to bottom, #3763e5 0, #3763e5 100%);
      background-repeat: no-repeat;
      background-size: cover;
      clip-path: var(--shape-0-path);
      display: block;
      mix-blend-mode: multiply;
      padding: 10px 16px;

      &::before,
      &::after {
        clip-path: var(--shape-1-path);
        content: '';
        display: block;
        inset: 0;
        mix-blend-mode: multiply;
        position: absolute;
        z-index: 1;
      }

      &::before {
        background-image: linear-gradient(
          to bottom,
          white 0%,
          rgba(255, 255, 255, 0.29) 46%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 0.32;
      }

      &::after {
        background-image: linear-gradient(
          to bottom,
          rgba(6, 43, 56, 0) 0%,
          #062b38 100%
        );
        opacity: 0.16;
      }
    }
  }
`;
