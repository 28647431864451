export const Container = `
  M 0, <%= calc(height * 0.498) %> C 0,
  <%= calc(height * 0.333) %>, <%= calc(width * 0.003) %>,
  <%= calc(height * 0.204) %>, <%= calc(width * 0.006) %>,
  <%= calc(height * 0.121) %> C <%= calc(width * 0.008) %>,
  <%= calc(height * 0.055) %>, <%= calc(width * 0.02) %>,
  <%= calc(height * 0.01) %>, <%= calc(width * 0.035) %>,
  <%= calc(height * 0.01) %> H <%= calc(width * 0.965) %> C <%=
    calc(width * 0.98) %>,
  <%= calc(height * 0.01) %>, <%= calc(width * 0.992) %>,
  <%= calc(height * 0.055) %>, <%= calc(width * 0.994) %>,
  <%= calc(height * 0.121) %> C <%= calc(width * 0.997) %>,
  <%= calc(height * 0.204) %>, <%= width %>,
  <%= calc(height * 0.333) %>, <%= width %>,
  <%= calc(height * 0.498) %> C <%= width %>,
  <%= calc(height * 0.663) %>, <%= calc(width * 0.997) %>,
  <%= calc(height * 0.792) %>, <%= calc(width * 0.994) %>,
  <%= calc(height * 0.876) %> C <%= calc(width * 0.992) %>,
  <%= calc(height * 0.941) %>, <%= calc(width * 0.98) %>,
  <%= calc(height * 0.986) %>, <%= calc(width * 0.965) %>,
  <%= calc(height * 0.986) %> H <%= calc(width * 0.035) %> C <%=
    calc(width * 0.02) %>,
  <%= calc(height * 0.986) %>, <%= calc(width * 0.008) %>,
  <%= calc(height * 0.941) %>, <%= calc(width * 0.006) %>,
  <%= calc(height * 0.876) %> C <%= calc(width * 0.003) %>,
  <%= calc(height * 0.792) %>, 0, <%= calc(height * 0.663) %>, 0,
  <%= calc(height * 0.498) %> Z
`;
