export const Container = `
  M 0, <%= calc(height * 0.5) %> C 0, <%= calc(height * 0.304) %>,
  <%= calc(width * 0.002) %>, <%= calc(height * 0.157) %>,
  <%= calc(width * 0.003) %>,
  <%= calc(height * 0.075) %> C <%= calc(width * 0.004) %>,
  <%= calc(height * 0.031) %>, <%= calc(width * 0.008) %>, 0,
  <%= calc(width * 0.012) %>,
  0 H <%= calc(width * 0.988) %> C <%= calc(width * 0.992) %>, 0,
  <%= calc(width * 0.996) %>, <%= calc(height * 0.031) %>,
  <%= calc(width * 0.997) %>,
  <%= calc(height * 0.075) %> C <%= calc(width * 0.998) %>,
  <%= calc(height * 0.157) %>, <%= width %>, <%= calc(height * 0.304) %>,
  <%= width %>, <%= calc(height * 0.5) %> C <%= width %>,
  <%= calc(height * 0.696) %>, <%= calc(width * 0.998) %>,
  <%= calc(height * 0.843) %>, <%= calc(width * 0.997) %>,
  <%= calc(height * 0.925) %> C <%= calc(width * 0.996) %>,
  <%= calc(height * 0.969) %>, <%= calc(width * 0.992) %>, <%= height %>,
  <%= calc(width * 0.988) %>,
  <%= height %> H <%= calc(width * 0.012) %> C <%= calc(width * 0.008) %>,
  <%= height %>, <%= calc(width * 0.004) %>, <%= calc(height * 0.969) %>,
  <%= calc(width * 0.003) %>,
  <%= calc(height * 0.925) %> C <%= calc(width * 0.002) %>,
  <%= calc(height * 0.843) %>, 0, <%= calc(height * 0.696) %>, 0,
  <%= calc(height * 0.5) %> Z
`;
