import { useEffect, useRef } from 'react';

import { THRESHOLD as threshold } from './constants';
import { observe } from './helpers';

export default () => {
  const ref = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    const { current: element } = ref;
    const root = element?.parentNode as Element;
    const observer = new IntersectionObserver(observe, {
      rootMargin: '-100px 0px 0px 0px',
      root,
      threshold,
    });

    observer.observe(element!);

    return () => observer.disconnect();
  }, []);

  return { ref };
};
