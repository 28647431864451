import apply from 'styled-components';
import { lazy } from 'react';

import render from './render';
import style from './style';

export const ASSETS = {
  company: '/assets/images/mock/logo.png',
  profile: '/assets/images/mock/profile.jpg',
};

export const styled = apply(render);

export const load = () =>
  Promise.all(Object.entries(ASSETS).map(preload))
    .then(delivery)
    .catch(delivery);

export const delivery = () => Promise.resolve({ default: styled`${style}` });

export const preload = ([namespace, src]: [string, string]) => {
  const {
    documentElement: { style },
  } = document;
  const persist = ([key, value]: [string, string]) => {
    const property = '--'.concat(namespace).concat('-').concat(key);

    return style.setProperty(property, value);
  };
  const create = (resolve: Function) => {
    const onload = (event: Event) => {
      const target = event.target as HTMLImageElement;
      const url = JSON.stringify(target.src);
      const entries = Object.entries({
        height: target.height.toString(),
        width: target.width.toString(),
        url: `url(${url})`,
      });

      return resolve(entries.forEach(persist));
    };

    return Object.assign(new Image(), { onerror: resolve, onload, src });
  };

  return new Promise(create);
};

export default lazy(load);
