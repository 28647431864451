import { css } from 'styled-components';

import * as Shapes from './shapes';

export default css`
  &[data-favorite='true'] {
    a::after {
      display: block;
    }
  }

  a {
    --shape: ${Shapes.Container};

    clip-path: var(--shape-path);
    color: #40699d;
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    padding: 12px 40px 12px 12px;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: background-color 0.1s ease, color 0.1s ease;
    white-space: nowrap;

    &::after {
      background-color: #3763e5;
      content: '';
      display: none;
      height: 16px;
      mask: center url(${process.env.PUBLIC_URL}/assets/vectors/icons/star.svg)
        no-repeat;
      mask-size: cover;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.1s ease;
      width: 16px;
    }

    &:hover,
    &:focus {
      background-color: #3763e5;
      color: #fff;

      &::after {
        background-color: #fff;
      }
    }
  }
`;
