import type * as Types from './types';
import Suggestion from './suggestion';

export default (({ className }) => (
  <article className={className}>
    <h1>
      <em>How can I help you today?</em>
    </h1>
    <nav>
      <h3>Some suggestions to start a chat with:</h3>
      <ul>
        <Suggestion title="I'm feeling curious">
          <span>I'm feeling curious </span>
          <span>🤓</span>
        </Suggestion>
        <Suggestion title="Which preferences would have the biggest impact?">
          Which preferences would have the biggest impact?
        </Suggestion>
        <Suggestion title="Which persona should I focus on?">
          Which persona should I focus on?
        </Suggestion>
        <Suggestion title="Which preferences would have the biggest impact?">
          Which preferences would have the biggest impact?
        </Suggestion>
      </ul>
    </nav>
  </article>
)) as Types.Default;
