import times from 'lodash-es/times';

export const count = (value: number) => (value + 1) / 100;

export const generate = () => times(100, count);

export const observe = (entries: Array<IntersectionObserverEntry>) =>
  Array.from(entries).forEach(persist);

export const persist = (entry: IntersectionObserverEntry) => {
  const { intersectionRatio, isIntersecting } = entry;
  const { style } = entry.target as HTMLElement;

  return [
    style.setProperty(`--scroll-ratio`, intersectionRatio.toString()),
    style.setProperty(`--scrolling`, Number(isIntersecting).toString()),
  ];
};
