import { useId, useMemo } from 'react';

import type { Props } from './types';

export default (props: Partial<Props>) => {
  const hash = useId();
  const id = useMemo(() => props.id || hash, [props.id, hash]);
  const defaultChecked = useMemo(
    () => props.defaultChecked || false,
    [props.defaultChecked]
  );

  return { defaultChecked, id };
};
