import { StrictMode } from 'react';
import { BrowserRouter as Routing } from 'react-router-dom';
import { ThemeProvider as Theming } from 'styled-components';

import theme from 'themes/default';
import { App, Style } from 'components';

export default () => (
  <StrictMode>
    <Routing>
      <Theming theme={theme}>
        <Style />
        <App />
      </Theming>
    </Routing>
  </StrictMode>
);
