export const Container = `
  M <%= calc(width * -0.00595238) %>,
  <%= calc(height * 0.5) %> C <%= calc(width * -0.00595238) %>,
  <%= calc(height * 0.1875) %>, <%= calc(width * 0) %>, 0, 0,
  0 H <%= width %> C <%= width %>, 0, <%= calc(width * 0.994048) %>,
  <%= calc(height * 0.1875) %>, <%= calc(width * 0.994048) %>,
  <%= calc(height * 0.5) %> C <%= calc(width * 0.994048) %>,
  <%= calc(height * 0.8125) %>, <%= width %>, <%= height %>, <%= width %>,
  <%= height %> H 0 C 0, <%= height %>, <%= calc(width * -0.00595238) %>,
  <%= calc(height * 0.8125) %>, <%= calc(width * -0.00595238) %>,
  <%= calc(height * 0.5) %> Z
`;

export const Field = `
  M 0, <%= calc(height * 0.5) %> C 0,
  <%= calc(height * 0.33128) %>, <%= calc(width * 0.00198248) %>,
  <%= calc(height * 0.198997) %>, <%= calc(width * 0.00381027) %>,
  <%= calc(height * 0.112987) %> C <%= calc(width * 0.0052289) %>,
  <%= calc(height * 0.045839) %>, <%= calc(width * 0.0132392) %>, 0,
  <%= calc(width * 0.0224489) %>,
  0 H <%= calc(width * 0.977551) %> C <%= calc(width * 0.986761) %>, 0,
  <%= calc(width * 0.994762) %>, <%= calc(height * 0.045839) %>,
  <%= calc(width * 0.996771) %>,
  <%= calc(height * 0.112987) %> C <%= calc(width * 0.99899) %>,
  <%= calc(height * 0.198997) %>, <%= width %>,
  <%= calc(height * 0.33128) %>, <%= width %>,
  <%= calc(height * 0.5) %> C <%= width %>,
  <%= calc(height * 0.66872) %>, <%= calc(width * 0.99899) %>,
  <%= calc(height * 0.80102) %>, <%= calc(width * 0.996771) %>,
  <%= calc(height * 0.887025) %> C <%= calc(width * 0.994762) %>,
  <%= calc(height * 0.954161) %>, <%= calc(width * 0.986761) %>,
  <%= height %>, <%= calc(width * 0.977551) %>,
  <%= height %> H <%= calc(width * 0.0224489) %> C <%=
    calc(width * 0.0132392) %>,
  <%= height %>, <%= calc(width * 0.0052289) %>,
  <%= calc(height * 0.954161) %>, <%= calc(width * 0.00381027) %>,
  <%= calc(height * 0.887025) %> C <%= calc(width * 0.00198248) %>,
  <%= calc(height * 0.80102) %>, 0, <%= calc(height * 0.66872) %>, 0,
  <%= calc(height * 0.5) %> Z
`;

export const Radio = {
  Container: `
    M <%= calc(width * 0.00350538) %>,
    <%= calc(height * 0.5) %> C <%= calc(width * 0.00350538) %>,
    <%= calc(height * 0.342281) %>, <%= calc(width * 0.00883084) %>,
    <%= calc(height * 0.2174) %>, <%= calc(width * 0.014117) %>,
    <%= calc(height * 0.131422) %> C <%= calc(width * 0.0190053) %>,
    <%= calc(height * 0.0520306) %>, <%= calc(width * 0.0428947) %>, 0,
    <%= calc(width * 0.0695547) %>,
    0 H <%= calc(width * 0.936929) %> C <%= calc(width * 0.963589) %>, 0,
    <%= calc(width * 0.987474) %>, <%= calc(height * 0.0520306) %>,
    <%= calc(width * 0.992362) %>,
    <%= calc(height * 0.131422) %> C <%= calc(width * 0.997649) %>,
    <%= calc(height * 0.2174) %>, <%= width %>,
    <%= calc(height * 0.342281) %>, <%= width %>,
    <%= calc(height * 0.5) %> C <%= width %>,
    <%= calc(height * 0.657719) %>, <%= calc(width * 0.997649) %>,
    <%= calc(height * 0.7826) %>, <%= calc(width * 0.992362) %>,
    <%= calc(height * 0.868578) %> C <%= calc(width * 0.987474) %>,
    <%= calc(height * 0.947969) %>, <%= calc(width * 0.963589) %>,
    <%= height %>, <%= calc(width * 0.936929) %>,
    <%= height %> H <%= calc(width * 0.0695547) %> C <%=
      calc(width * 0.0428947) %>,
    <%= height %>, <%= calc(width * 0.0190053) %>,
    <%= calc(height * 0.947969) %>, <%= calc(width * 0.014117) %>,
    <%= calc(height * 0.868578) %> C <%= calc(width * 0.00883084) %>,
    <%= calc(height * 0.7826) %>, <%= calc(width * 0.00350538) %>,
    <%= calc(height * 0.657719) %>, <%= calc(width * 0.00350538) %>,
    <%= calc(height * 0.5) %> Z
  `,
  Border: `
    M <%= calc(width * 0.982) %>,
    <%= calc(height * 0.847) %> L <%= calc(width * 0.972) %>,
    <%= calc(height * 0.841) %> L <%= calc(width * 0.982) %>,
    <%= calc(height * 0.847) %> Z M <%= calc(width * 0.014) %>,
    <%= calc(height * 0.147) %> C <%= calc(width * 0.008) %>,
    <%= calc(height * 0.229) %>, <%= calc(width * 0.003) %>,
    <%= calc(height * 0.35) %>, <%= calc(width * 0.003) %>,
    <%= calc(height * 0.5) %> H <%= calc(width * 0.024) %> C <%=
      calc(width * 0.024) %>,
    <%= calc(height * 0.353) %>, <%= calc(width * 0.029) %>,
    <%= calc(height * 0.238) %>, <%= calc(width * 0.034) %>,
    <%= calc(height * 0.159) %> L <%= calc(width * 0.014) %>,
    <%= calc(height * 0.147) %> Z M <%= calc(width * 0.928) %>,
    0 H <%= calc(width * 0.079) %> V <%= calc(height * 0.059) %> H <%=
      calc(width * 0.928) %> V 0 Z M <%= calc(width * 1.003) %>,
    <%= calc(height * 0.5) %> C <%= calc(width * 1.003) %>,
    <%= calc(height * 0.35) %>, <%= calc(width * 0.998) %>,
    <%= calc(height * 0.229) %>, <%= calc(width * 0.993) %>,
    <%= calc(height * 0.147) %> L <%= calc(width * 0.972) %>,
    <%= calc(height * 0.159) %> C <%= calc(width * 0.977) %>,
    <%= calc(height * 0.238) %>, <%= calc(width * 0.982) %>,
    <%= calc(height * 0.353) %>, <%= calc(width * 0.982) %>,
    <%= calc(height * 0.5) %> H <%= calc(width * 1.003) %> Z M <%=
      calc(width * 0.993) %>,
    <%= calc(height * 0.853) %> C <%= calc(width * 0.998) %>,
    <%= calc(height * 0.771) %>, <%= calc(width * 1.003) %>,
    <%= calc(height * 0.65) %>, <%= calc(width * 1.003) %>,
    <%= calc(height * 0.5) %> H <%= calc(width * 0.982) %> C <%=
      calc(width * 0.982) %>,
    <%= calc(height * 0.647) %>, <%= calc(width * 0.977) %>,
    <%= calc(height * 0.762) %>, <%= calc(width * 0.972) %>,
    <%= calc(height * 0.841) %> L <%= calc(width * 0.993) %>,
    <%= calc(height * 0.853) %> Z M <%= calc(width * 0.079) %>,
    <%= height %> H <%= calc(width * 0.928) %> V <%=
      calc(height * 0.941) %> H <%= calc(width * 0.079) %> V <%= height
      %> Z M <%= calc(width * 0.003) %>,
    <%= calc(height * 0.5) %> C <%= calc(width * 0.003) %>,
    <%= calc(height * 0.65) %>, <%= calc(width * 0.008) %>,
    <%= calc(height * 0.771) %>, <%= calc(width * 0.014) %>,
    <%= calc(height * 0.853) %> L <%= calc(width * 0.034) %>,
    <%= calc(height * 0.841) %> C <%= calc(width * 0.029) %>,
    <%= calc(height * 0.762) %>, <%= calc(width * 0.024) %>,
    <%= calc(height * 0.647) %>, <%= calc(width * 0.024) %>,
    <%= calc(height * 0.5) %> Z M <%= calc(width * 0.972) %>,
    <%= calc(height * 0.841) %> C <%= calc(width * 0.969) %>,
    <%= calc(height * 0.9) %>, <%= calc(width * 0.951) %>,
    <%= calc(height * 0.941) %>, <%= calc(width * 0.928) %>,
    <%= calc(height * 0.941) %> V <%= height %> C <%=
      calc(width * 0.959) %>,
    <%= height %>, <%= calc(width * 0.987) %>,
    <%= calc(height * 0.944) %>, <%= calc(width * 0.993) %>,
    <%= calc(height * 0.853) %> L <%= calc(width * 0.972) %>,
    <%= calc(height * 0.841) %> Z M <%= calc(width * 0.928) %>,
    <%= calc(height * 0.059) %> C <%= calc(width * 0.951) %>,
    <%= calc(height * 0.059) %>, <%= calc(width * 0.969) %>,
    <%= calc(height * 0.1) %>, <%= calc(width * 0.972) %>,
    <%= calc(height * 0.159) %> L <%= calc(width * 0.993) %>,
    <%= calc(height * 0.147) %> C <%= calc(width * 0.987) %>,
    <%= calc(height * 0.056) %>, <%= calc(width * 0.959) %>, 0,
    <%= calc(width * 0.928) %>,
    0 V <%= calc(height * 0.059) %> Z M <%= calc(width * 0.079) %>,
    <%= calc(height * 0.941) %> C <%= calc(width * 0.057) %>,
    <%= calc(height * 0.941) %>, <%= calc(width * 0.038) %>,
    <%= calc(height * 0.9) %>, <%= calc(width * 0.034) %>,
    <%= calc(height * 0.841) %> L <%= calc(width * 0.014) %>,
    <%= calc(height * 0.853) %> C <%= calc(width * 0.02) %>,
    <%= calc(height * 0.944) %>, <%= calc(width * 0.048) %>,
    <%= height %>, <%= calc(width * 0.079) %>,
    <%= height %> V <%= calc(height * 0.941) %> Z M <%=
      calc(width * 0.034) %>,
    <%= calc(height * 0.159) %> C <%= calc(width * 0.038) %>,
    <%= calc(height * 0.1) %>, <%= calc(width * 0.057) %>,
    <%= calc(height * 0.059) %>, <%= calc(width * 0.079) %>,
    <%= calc(height * 0.059) %> V 0 C <%= calc(width * 0.048) %>, 0,
    <%= calc(width * 0.02) %>, <%= calc(height * 0.056) %>,
    <%= calc(width * 0.014) %>,
    <%= calc(height * 0.147) %> L <%= calc(width * 0.034) %>,
    <%= calc(height * 0.159) %> Z
  `,
};
