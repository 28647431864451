import { css } from 'styled-components';

export default css`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  position: fixed;
  right: 18px;
  top: 35px;
  width: 100%;
  z-index: 1;

  &,
  p {
    gap: 20px;
  }

  h3 {
    left: 69px;
    position: fixed;
    top: 37px;

    *:not(a) {
      display: none;
    }

    a {
      background: url('${process.env
          .PUBLIC_URL}/assets/vectors/logos/solsten-198x36.svg')
        no-repeat;
      background-size: cover;
      display: block;
      font-size: 0;
      height: 36px;
      width: 198px;
    }
  }

  p {
    align-items: center;
    display: flex;

    span {
      display: none;
    }

    a {
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      font-size: 0;

      &[data-entity='company'] {
        background-image: var(--company-url);
        height: calc(var(--company-height) * 1px);
        width: calc(var(--company-width) * 1px);
      }

      &[data-entity='profile'] {
        background-image: var(--profile-url);
        border-radius: 50%;
        box-shadow: 0px -4px 4px 0px rgba(255, 255, 255, 0.6),
          0px 12px 20px 0px rgba(28, 39, 140, 0.1);
        height: 32px;
        width: 32px;
      }
    }
  }

  nav {
    a {
      align-items: center;
      color: #040b4a;
      display: flex;
      flex-direction: row-reverse;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.2px;
      line-height: 100%;
      position: relative;
      text-decoration: none;

      &::before,
      &::after {
        content: '';
        display: block;
      }

      &::before {
        background-color: #040b4a;
        height: 20px;
        margin-left: 2px;
        mask: center
          url(${process.env.PUBLIC_URL}/assets/vectors/icons/bell.svg) no-repeat;
        mask-size: cover;
        width: 20px;
      }

      &::after {
        height: 8.5px;
        background: center
          url(${process.env.PUBLIC_URL}/assets/vectors/markers/notification.svg)
          no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 8.5px;
      }

      span,
      em {
        display: none;
      }
    }
  }
`;
