import type * as Types from './types';
import use from './hooks';

export default (({ children, className }) => {
  const { ref } = use();

  return (
    <details className={className} ref={ref} open>
      {children}
    </details>
  );
}) as Types.Default;
