export const Field = `
  M 0, <%= calc(height * 0.5) %> C 0, <%= calc(height * 0.314) %>,
  <%= calc(width * 0.001) %>, <%= calc(height * 0.172) %>,
  <%= calc(width * 0.002) %>,
  <%= calc(height * 0.088) %> C <%= calc(width * 0.002) %>,
  <%= calc(height * 0.037) %>, <%= calc(width * 0.005) %>, 0,
  <%= calc(width * 0.008) %>,
  0 H <%= calc(width * 0.992) %> C <%= calc(width * 0.995) %>, 0,
  <%= calc(width * 0.998) %>, <%= calc(height * 0.037) %>,
  <%= calc(width * 0.998) %>,
  <%= calc(height * 0.088) %> C <%= calc(width * 0.999) %>,
  <%= calc(height * 0.172) %>, <%= width %>, <%= calc(height * 0.314) %>,
  <%= width %>, <%= calc(height * 0.5) %> C <%= width %>,
  <%= calc(height * 0.686) %>, <%= calc(width * 0.999) %>,
  <%= calc(height * 0.828) %>, <%= calc(width * 0.998) %>,
  <%= calc(height * 0.912) %> C <%= calc(width * 0.998) %>,
  <%= calc(height * 0.963) %>, <%= calc(width * 0.995) %>, <%= height %>,
  <%= calc(width * 0.992) %>,
  <%= height %> H <%= calc(width * 0.008) %> C <%= calc(width * 0.005) %>,
  <%= height %>, <%= calc(width * 0.002) %>, <%= calc(height * 0.963) %>,
  <%= calc(width * 0.002) %>,
  <%= calc(height * 0.912) %> C <%= calc(width * 0.001) %>,
  <%= calc(height * 0.828) %>, 0, <%= calc(height * 0.686) %>, 0,
  <%= calc(height * 0.5) %> Z
`;

export const Button = {
  Container: `
    M 0, <%= calc(height * 0.5) %> C 0, <%= calc(height * 0.331) %>,
    <%= calc(width * 0.005) %>, <%= calc(height * 0.199) %>,
    <%= calc(width * 0.01) %>,
    <%= calc(height * 0.113) %> C <%= calc(width * 0.013) %>,
    <%= calc(height * 0.046) %>, <%= calc(width * 0.034) %>, 0,
    <%= calc(width * 0.057) %>,
    0 H <%= calc(width * 0.943) %> C <%= calc(width * 0.966) %>, 0,
    <%= calc(width * 0.987) %>, <%= calc(height * 0.046) %>,
    <%= calc(width * 0.99) %>,
    <%= calc(height * 0.113) %> C <%= calc(width * 0.995) %>,
    <%= calc(height * 0.199) %>, <%= width %>, <%= calc(height * 0.331) %>,
    <%= width %>, <%= calc(height * 0.5) %> C <%= width %>,
    <%= calc(height * 0.669) %>, <%= calc(width * 0.995) %>,
    <%= calc(height * 0.801) %>, <%= calc(width * 0.99) %>,
    <%= calc(height * 0.887) %> C <%= calc(width * 0.987) %>,
    <%= calc(height * 0.954) %>, <%= calc(width * 0.966) %>, <%= height %>,
    <%= calc(width * 0.943) %>,
    <%= height %> H <%= calc(width * 0.057) %> C <%= calc(width * 0.034) %>,
    <%= height %>, <%= calc(width * 0.013) %>, <%= calc(height * 0.954) %>,
    <%= calc(width * 0.01) %>,
    <%= calc(height * 0.887) %> C <%= calc(width * 0.005) %>,
    <%= calc(height * 0.801) %>, 0, <%= calc(height * 0.669) %>, 0,
    <%= calc(height * 0.5) %> Z
  `,
  Border: `
    M <%= calc(width * 0.974) %>,
    <%= calc(height * 0.852) %> L <%= calc(width * 0.957) %>,
    <%= calc(height * 0.845) %> L <%= calc(width * 0.974) %>,
    <%= calc(height * 0.852) %> Z M <%= calc(width * 0.01) %>,
    <%= calc(height * 0.141) %> C <%= calc(width * 0.005) %>,
    <%= calc(height * 0.221) %>, 0, <%= calc(height * 0.344) %>, 0,
    <%= calc(height * 0.5) %> H <%= calc(width * 0.034) %> C <%=
    calc(width * 0.034) %>,
    <%= calc(height * 0.349) %>, <%= calc(width * 0.038) %>,
    <%= calc(height * 0.231) %>, <%= calc(width * 0.043) %>,
    <%= calc(height * 0.155) %> L <%= calc(width * 0.01) %>,
    <%= calc(height * 0.141) %> Z M <%= calc(width * 0.928) %>,
    0 H <%= calc(width * 0.072) %> V <%= calc(height * 0.091) %> H <%=
    calc(width * 0.928) %> V 0 Z M <%= width %>,
    <%= calc(height * 0.5) %> C <%= width %>, <%= calc(height * 0.344) %>,
    <%= calc(width * 0.995) %>, <%= calc(height * 0.221) %>,
    <%= calc(width * 0.99) %>,
    <%= calc(height * 0.141) %> L <%= calc(width * 0.957) %>,
    <%= calc(height * 0.155) %> C <%= calc(width * 0.962) %>,
    <%= calc(height * 0.231) %>, <%= calc(width * 0.966) %>,
    <%= calc(height * 0.349) %>, <%= calc(width * 0.966) %>,
    <%= calc(height * 0.5) %> H <%= width %> Z M <%= calc(width * 0.99) %>,
    <%= calc(height * 0.859) %> C <%= calc(width * 0.995) %>,
    <%= calc(height * 0.779) %>, <%= width %>, <%= calc(height * 0.656) %>,
    <%= width %>,
    <%= calc(height * 0.5) %> H <%= calc(width * 0.966) %> C <%=
    calc(width * 0.966) %>,
    <%= calc(height * 0.651) %>, <%= calc(width * 0.962) %>,
    <%= calc(height * 0.769) %>, <%= calc(width * 0.957) %>,
    <%= calc(height * 0.845) %> L <%= calc(width * 0.99) %>,
    <%= calc(height * 0.859) %> Z M <%= calc(width * 0.072) %>,
    <%= height %> H <%= calc(width * 0.928) %> V <%= calc(height * 0.909) %> H
    <%= calc(width * 0.072) %> V <%= height %> Z M 0,
    <%= calc(height * 0.5) %> C 0, <%= calc(height * 0.656) %>,
    <%= calc(width * 0.005) %>, <%= calc(height * 0.779) %>,
    <%= calc(width * 0.01) %>,
    <%= calc(height * 0.859) %> L <%= calc(width * 0.043) %>,
    <%= calc(height * 0.845) %> C <%= calc(width * 0.038) %>,
    <%= calc(height * 0.769) %>, <%= calc(width * 0.034) %>,
    <%= calc(height * 0.651) %>, <%= calc(width * 0.034) %>,
    <%= calc(height * 0.5) %> H 0 Z M <%= calc(width * 0.957) %>,
    <%= calc(height * 0.845) %> C <%= calc(width * 0.955) %>,
    <%= calc(height * 0.882) %>, <%= calc(width * 0.943) %>,
    <%= calc(height * 0.909) %>, <%= calc(width * 0.928) %>,
    <%= calc(height * 0.909) %> V <%= height %> C <%= calc(width * 0.958) %>,
    <%= height %>, <%= calc(width * 0.985) %>, <%= calc(height * 0.944) %>,
    <%= calc(width * 0.99) %>,
    <%= calc(height * 0.859) %> L <%= calc(width * 0.957) %>,
    <%= calc(height * 0.845) %> Z M <%= calc(width * 0.928) %>,
    <%= calc(height * 0.091) %> C <%= calc(width * 0.943) %>,
    <%= calc(height * 0.091) %>, <%= calc(width * 0.955) %>,
    <%= calc(height * 0.118) %>, <%= calc(width * 0.957) %>,
    <%= calc(height * 0.155) %> L <%= calc(width * 0.99) %>,
    <%= calc(height * 0.141) %> C <%= calc(width * 0.985) %>,
    <%= calc(height * 0.056) %>, <%= calc(width * 0.958) %>, 0,
    <%= calc(width * 0.928) %>,
    0 V <%= calc(height * 0.091) %> Z M <%= calc(width * 0.072) %>,
    <%= calc(height * 0.909) %> C <%= calc(width * 0.057) %>,
    <%= calc(height * 0.909) %>, <%= calc(width * 0.045) %>,
    <%= calc(height * 0.882) %>, <%= calc(width * 0.043) %>,
    <%= calc(height * 0.845) %> L <%= calc(width * 0.01) %>,
    <%= calc(height * 0.859) %> C <%= calc(width * 0.015) %>,
    <%= calc(height * 0.944) %>, <%= calc(width * 0.042) %>, <%= height %>,
    <%= calc(width * 0.072) %>,
    <%= height %> V <%= calc(height * 0.909) %> Z M <%= calc(width * 0.043) %>,
    <%= calc(height * 0.155) %> C <%= calc(width * 0.045) %>,
    <%= calc(height * 0.118) %>, <%= calc(width * 0.057) %>,
    <%= calc(height * 0.091) %>, <%= calc(width * 0.072) %>,
    <%= calc(height * 0.091) %> V 0 C <%= calc(width * 0.042) %>, 0,
    <%= calc(width * 0.015) %>, <%= calc(height * 0.056) %>,
    <%= calc(width * 0.01) %>,
    <%= calc(height * 0.141) %> L <%= calc(width * 0.043) %>,
    <%= calc(height * 0.155) %> Z
  `,
};
