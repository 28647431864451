import { css } from 'styled-components';

export default css`
  --sidebar-width: 334px;

  background-image: linear-gradient(
    0deg,
    rgba(204, 226, 255, 0.56) 0%,
    rgba(204, 226, 255, 0.56) 100%
  );
  inset: 0;
  position: absolute;

  main {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    position: fixed;
    width: 100%;
  }
`;
