import get from 'lodash-es/get';
import identity from 'lodash-es/identity';
import template from 'lodash-es/template';
import times from 'lodash-es/times';
import { useCallback, useEffect, useRef } from 'react';

import type { Props } from './types';

export default ({ children, slots }: Props) => {
  const ref = useRef(get(children, 'ref.current', null)!);
  const persist = useCallback(
    (entry: ResizeObserverEntry) => {
      const element = entry.target as HTMLElement;
      const { style } = element;
      const { height, width } = element.getBoundingClientRect();
      const apply = (slot = -1) => {
        const prefix = '--shape'.concat(!!~slot ? `-${slot}` : '');
        const raw = window.getComputedStyle(element).getPropertyValue(prefix);
        const draw = template(raw);
        const shape = draw({ calc: identity, height, width });
        const path = JSON.stringify(shape);

        return style.setProperty(`${prefix}-path`, `path(${path})`);
      };

      return slots ? times(slots).forEach(apply) : apply();
    },
    [slots]
  );
  const observe = useCallback(
    (entries: Array<ResizeObserverEntry>) =>
      Array.from(entries).forEach(persist),
    [persist]
  );

  useEffect(() => {
    const observer = new ResizeObserver(observe);

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [observe]);

  return { ref };
};
