import type * as Types from './types';
import Period from './period';
import Result from './result';
import Settings from './settings';

export default (({ className }) => (
  <section className={className}>
    <h3>Results</h3>
    <Settings />
    <article>
      <Period>
        <summary>Today</summary>
        <ul>
          <Result title="Persona focus" favorite>
            Persona focus
          </Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact" favorite>
            Preferences for impact
          </Result>
          <Result title="Compare to genre">Compare to genre</Result>
        </ul>
      </Period>
      <Period>
        <summary>Yesterday</summary>
        <ul>
          <Result
            title="Creative for high LTV personas with a huge and loooooong title"
            favorite
          >
            Creative for high LTV personas with a huge and loooooong title
          </Result>
        </ul>
      </Period>
      <Period>
        <summary>Today</summary>
        <ul>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
        </ul>
      </Period>
      <Period>
        <summary>Yesterday</summary>
        <ul>
          <Result title="Creative for high LTV personas">
            Creative for high LTV personas
          </Result>
        </ul>
      </Period>
      <Period>
        <summary>Today</summary>
        <ul>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
        </ul>
      </Period>
      <Period>
        <summary>Yesterday</summary>
        <ul>
          <Result title="Creative for high LTV personas">
            Creative for high LTV personas
          </Result>
        </ul>
      </Period>
      <Period>
        <summary>Today</summary>
        <ul>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
        </ul>
      </Period>
      <Period>
        <summary>Yesterday</summary>
        <ul>
          <Result title="Creative for high LTV personas">
            Creative for high LTV personas
          </Result>
        </ul>
      </Period>
      <Period>
        <summary>Today</summary>
        <ul>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre" favorite>
            Compare to genre
          </Result>
          <Result title="Persona focus">Persona focus</Result>
          <Result title="Preferences for impact">Preferences for impact</Result>
          <Result title="Compare to genre">Compare to genre</Result>
        </ul>
      </Period>
    </article>
  </section>
)) as Types.Default;
