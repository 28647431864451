import { css } from 'styled-components';

import { Radio } from 'components/widgets/fields';

import * as Shapes from './shapes';

export default css`
  --shape: ${Shapes.Container};

  background-image: linear-gradient(
    0deg,
    rgba(204, 226, 255, 0.56) 0%,
    rgba(204, 226, 255, 0.56) 100%
  );
  box-shadow: -2px -4px 10px 0px rgba(255, 255, 255, 0.7),
    1px 3px 6px 0px rgba(74, 128, 255, 0.28),
    2px 6px 16px 0px rgba(74, 128, 255, 0.28);
  display: flex;
  flex-direction: column;
  filter: drop-shadow(-2px -4px 10px rgba(255, 255, 255, 0.7))
    drop-shadow(1px 3px 6px rgba(74, 128, 255, 0.28))
    drop-shadow(2px 6px 16px rgba(74, 128, 255, 0.28));
  padding-top: 110px;
  position: relative;
  width: var(--sidebar-width);

  &::before {
    background-image: linear-gradient(
      0deg,
      rgba(204, 226, 255, 0.56) 0%,
      rgba(204, 226, 255, 0.56) 100%
    );
    background-size: 100% 100%;
    clip-path: var(--shape-path);
    content: '';
    display: block;
    inset: 0;
    position: absolute;
    z-index: -1;
  }

  & > {
    h2 {
      display: none;
    }

    nav,
    form {
      margin: 0 20px;
    }

    nav {
      a {
        --shape: ${Shapes.Field};

        align-items: center;
        background-color: rgba(110, 161, 255, 0.16);
        clip-path: var(--shape-path);
        color: #3763e5;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        gap: 8px;
        justify-content: center;
        line-height: 130%;
        padding: 11px 0;
        text-decoration: none;
        transition: background-color 0.075s ease, color 0.075s ease;

        &::after {
          background-color: #3763e5;
          content: '';
          display: block;
          height: 16px;
          mask: center
            url(${process.env.PUBLIC_URL}/assets/vectors/icons/pencil.svg)
            no-repeat;
          mask-size: cover;
          transition: background-color 0.075s ease;
          width: 16px;
        }

        &:hover {
          background-color: #3763e5;
          color: #fff;

          &::after {
            background-color: #fff;
          }
        }
      }
    }

    form {
      margin-top: 12px;

      & > fieldset {
        position: relative;

        & > {
          legend {
            display: none;
          }
        }
      }

      [data-field='level'] {
        --checked: 1;
        --gap: 0.33px;
        --items: 3;
        --shape: ${Shapes.Field};
        --slot: calc(
          (100% - ((var(--gap) * (var(--items) - 1)) + 8px)) / var(--items)
        );

        background-color: rgba(110, 161, 255, 0.16);
        clip-path: var(--shape-path);
        display: grid;
        gap: var(--gap);
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        justify-content: space-between;
        padding: 4px;
        position: relative;

        &:has(${Radio}:nth-of-type(1) input:checked) {
          --checked: 1;
        }

        &:has(${Radio}:nth-of-type(2) input:checked) {
          --checked: 2;
        }

        &:has(${Radio}:nth-of-type(3) input:checked) {
          --checked: 3;
        }

        legend {
          --shape-0: ${Shapes.Radio.Container};
          --shape-1: ${Shapes.Radio.Border};

          filter: drop-shadow(1px 2px 4px rgba(110, 161, 255, 0.16));
          font-size: 0;
          height: calc(100% - 8px);
          left: calc(((var(--checked) - 1) * var(--slot)) + 4px);
          position: absolute;
          transition: left 0.15s ease;
          top: 50%;
          transform: translateY(-50%);
          width: var(--slot);

          &::before,
          &::after {
            content: '';
            display: block;
            inset: 0;
            position: absolute;
          }

          &::before {
            background-color: #fff;
            clip-path: var(--shape-0-path);
          }

          &::after {
            background-image: linear-gradient(
                to bottom right,
                rgba(106, 149, 210, 0) 0%,
                rgba(106, 149, 210, 0.16) 100%
              ),
              linear-gradient(
                74deg,
                white 0%,
                rgba(255, 255, 255, 0.1169028) 45.9761%,
                rgba(255, 255, 255, 0) 100%
              );
            clip-path: var(--shape-1-path);
          }
        }

        ${Radio} {
          display: inline-grid;
          z-index: 1;

          label {
            color: #84a6d1;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            padding: 8px 0;
            text-align: center;
            transition: color 0.15s ease;
            user-select: none;

            &:is(input:checked + label),
            &:hover,
            &:focus {
              color: #3763e5;
            }
          }
        }
      }

      [data-field='keywords'] {
        --shape: ${Shapes.Field};

        margin-top: 12px;

        label {
          display: none;
        }

        input {
          background-color: rgba(110, 161, 255, 0.1);
          border: none;
          clip-path: var(--shape-path);
          display: block;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          outline: none;
          padding: 10px 40px 12px 16px;
          transition: background-color 0.6s ease;
          width: calc(100% - 56px);

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            background-color: #fff;
          }

          &::placeholder {
            color: #84a6d1;
          }
        }
      }

      button[type='submit'] {
        background-color: #84a6d1;
        bottom: 10px;
        cursor: pointer;
        font-size: 0;
        height: 20px;
        mask: center
          url(${process.env
            .PUBLIC_URL}/assets/vectors/icons/magnifying-glass.svg)
          no-repeat;
        mask-size: cover;
        position: absolute;
        right: 10px;
        width: 20px;
      }
    }
  }
`;
