import property from 'lodash-es/property';
import { type Interpolation, createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body,
  div#root {
    min-height: 100vh;
  }

  html {
    background-color: #fbfcfc;
    opacity: 1;
  }

  body {
    font-family: ${property('theme.typography.main') as Interpolation<object>};
  }

  button {
    border: 0;
    margin: 0;
    padding: 0;
  }

  nav {
    h4 {
      display: none;
    }
  }

  pre {
    font-size: 0.75rem;
  }
`;
