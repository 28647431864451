import { css } from 'styled-components';

import { scroll } from 'style/behaviors';

import Suggestion from '../suggestion';
import * as Shapes from './shapes';

export default css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &,
  & + form,
  & + form > fieldset {
    &::before,
    &::after {
      align-items: center;
      content: '';
      display: flex;
      height: 100%;
      justify-content: center;
      position: fixed;
      right: 0;
      top: 0;
      width: calc(100% - var(--sidebar-width));
      z-index: -1;
    }
  }

  &::before {
    background-color: #e2efff;
    opacity: 0.6;
  }

  &::after {
  }

  & > {
    h1 {
      color: #3763e5;
      font-size: 32px;
      line-height: 40px;
      position: relative;
      text-align: center;

      &::before,
      &::after {
        content: '';
        display: block;
        left: 50%;
        position: absolute;
      }

      &::before {
        background: center
          url(${process.env
            .PUBLIC_URL}/assets/vectors/components/app/chat/intro/splash.svg)
          no-repeat;
        background-size: cover;
        bottom: calc(100% - 300px);
        height: 921px;
        transform: scale(1) translateX(calc(-50% - 50px));
        width: 1180px;
      }

      &::after {
        background: center
          url(${process.env
            .PUBLIC_URL}/assets/images/components/app/chat/intro/splash.png)
          no-repeat;
        background-size: cover;
        bottom: calc(100% + 15px);
        height: 443px;
        transform: translateX(-50%);
        width: 411px;
      }

      &,
      em {
        font-style: normal;
        font-weight: 700;
      }

      em {
        position: relative;
        z-index: 1;
      }
    }

    nav {
      margin-top: 14px;
      mask-image: linear-gradient(to left, transparent 0%, black 10%);
      mask-mode: alpha;
      padding-top: 117.5px;
      position: relative;

      h3 {
        display: none;
      }

      ul {
        ${scroll};

        display: flex;
        flex-direction: row;
        gap: 8px;
        overflow-x: auto;
        padding-bottom: 7px;
      }

      ${Suggestion} {
        &:first-of-type {
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          top: 0;

          a {
            --shape: ${Shapes.Container};

            background-color: rgba(255, 255, 255, 0.32);
            color: #3763e5;
          }
        }
      }
    }
  }

  & + form {
    &::before {
      background-image: radial-gradient(
        ellipse at 2094.92px 109.267px,
        rgba(255, 255, 255, 0) 63.68%,
        rgba(255, 255, 255, 0.827451) 79.61%,
        rgba(255, 255, 255, 1) 89.13%
      );
      background-size: cover;
      opacity: 0.77;
      transform: rotate(180deg);
      transform-origin: center;
    }

    &::after {
      background-image: radial-gradient(
        ellipse at 372.137px 871.316px,
        white 0%,
        #f5ffff 41.323%,
        #a0d7fe 67.157%,
        #4355f3 100%
      );
      filter: blur(13px); /* Approximation of the stdDeviation */
      transform: rotate(-48.2393deg) scale(2.5);
      transform-origin: center;
      z-index: -2;
    }

    & > fieldset {
      &::before {
        background: radial-gradient(
          ellipse at 548.329px 833.274px,
          #bce8ea 0%,
          rgba(153, 202, 250, 0.49) 48.73%,
          rgba(112, 0, 255, 0) 100%
        );
        filter: blur(64px);
        transform: rotate(180deg);
      }

      &::after {
        background-image: linear-gradient(
          to left,
          rgba(77, 180, 255, 1) 20.07%,
          rgba(142, 114, 255, 0.6) 60.92%,
          rgba(134, 114, 255, 0) 85.53%
        );
        filter: blur(28px);
        opacity: 0.2;
      }
    }
  }
`;
