import type { FormEvent } from 'react';

export const check = (element: Element) =>
  (element instanceof HTMLElement && element.focus()) ||
  element === document.activeElement;

export const prevent = (event: FormEvent) => event.preventDefault();

export const situate = ({ elements }: HTMLFormElement) =>
  Array.from(elements).find(check);
