import apply from 'styled-components';

import render from './render';
import style from './style';

export const styled = apply(render);

export default styled`
  ${style}
`;
