import { useEffect, useRef } from 'react';

import { prevent as onSubmit, situate } from './helpers';

export default () => {
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => void situate(ref.current!), []);

  return { onSubmit, ref };
};
